/* Modern color palette variables */
:root {
    --primary-color: #6B8AFF;      /* Blue from graphs */
    --primary-hover: #5B7AEF;      /* Darker blue */
    --background-main: #1A1B2E;    /* Dark background */
    --background-paper: #242642;   /* Slightly lighter dark for cards */
    --text-primary: #FFFFFF;       /* White text */
    --text-secondary: #B4B7C5;     /* Light gray text */
    --accent-success: #42D7A0;     /* Green from environmental score */
    --accent-warning: #FFB547;     /* Orange/Yellow from graphs */
    --accent-info: #6B8AFF;        /* Blue from graphs */
    --border-color: #2F324D;       /* Darker border color */
    --hover-color: #363856;        /* Slightly lighter than border for hover states */
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background-main);
    color: var(--text-primary);
  }
  
  h1, h2, h3, h4, h5, h6 {
    color: var(--text-primary);
  }
  
  p {
    color: var(--text-secondary);
  }
  
  /* MUI Paper override */
  .MuiPaper-root {
    background-color: var(--background-paper) !important;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important;
    border: 1px solid var(--border-color) !important;
  }
  
  /* Button styles */
  .MuiButton-contained {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
  
  .MuiButton-contained:hover {
    background-color: var(--primary-hover) !important;
  }
  
  /* Input fields */
  .MuiTextField-root, .MuiSelect-root, .MuiInputBase-root {
    background-color: var(--background-paper) !important;
    color: var(--text-primary) !important;
  }
  
  .MuiInputLabel-root, .MuiSelect-icon {
    color: var(--text-secondary) !important;
  }
  
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.15) !important;
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.25) !important;
  }
  
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color) !important;
  }
  
  /* Data Grid customization */
  .MuiDataGrid-root {
    border-color: var(--border-color) !important;
    color: var(--text-primary) !important;
  }
  
  .MuiDataGrid-columnHeader {
    background-color: var(--background-paper) !important;
    color: var(--text-primary) !important;
  }
  
  .MuiDataGrid-cell {
    color: var(--text-primary) !important;
  }
  
  /* Chart colors */
  .recharts-text {
    fill: var(--text-secondary) !important;
  }
  
  .recharts-cartesian-grid line {
    stroke: var(--border-color) !important;
  }
  
  .recharts-line-principal {
    stroke: var(--primary-color) !important;
  }
  
  .recharts-line-interest {
    stroke: var(--accent-warning) !important;
  }
  
  .recharts-line-prepayment {
    stroke: var(--accent-success) !important;
  }
  
  /* Date picker customization */
  .MuiPickersDay-root {
    color: var(--text-secondary) !important;
    background-color: var(--background-paper) !important;
  }

  .MuiDateCalendar-root .MuiDayPicker-weekDayLabel,
  .MuiDateCalendar-root .MuiTypography-root {
    color: var(--text-primary) !important;
  }  

  .MuiDayPicker-header .MuiDayPicker-weekDayLabel,
.MuiDayPicker-header span {
  color: var(--text-primary) !important;
  }

  .MuiPickersCalendarHeader-label,
  .MuiDayPicker-weekDayLabel,
  .MuiTypography-caption {
    color: var(--text-primary) !important;
  }
  
  .MuiPickersYear-yearButton {
    color: var(--text-secondary) !important;
  }
  
  .MuiPickersMonth-monthButton {
    color: var(--text-secondary) !important;
  }
  
  .MuiPickersDay-root:hover {
    background-color: var(--hover-color) !important;
  }
  
  .MuiPickersDay-today {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
  
  .MuiPickersDay-selected {
    background-color: var(--primary-color) !important;
    color: white !important;
  }
  
  /* Calendar popup background */
  .MuiPaper-root.MuiPickersPopper-paper {
    background-color: var(--background-paper) !important;
    border: 1px solid rgba(255, 255, 255, 0.15) !important;
  }
  
  /* Calendar header arrows */
  .MuiPickersCalendarHeader-switchViewButton,
  .MuiPickersArrowSwitcher-button {
    color: var(--text-secondary) !important;
  }
  
  /* Select dropdown */
  .MuiMenu-paper {
    background-color: var(--background-paper) !important;
    border: 1px solid var(--border-color) !important;
  }
  
  .MuiMenuItem-root {
    color: var(--text-primary) !important;
  }
  
  .MuiMenuItem-root:hover {
    background-color: var(--hover-color) !important;
  }
  
  /* Tooltip customization */
  .MuiTooltip-tooltip {
    background-color: var(--background-paper) !important;
    border: 1px solid var(--border-color) !important;
    color: var(--text-primary) !important;
  }

  /* Calendar icons and weekday labels */
.MuiSvgIcon-root.MuiPickersCalendarHeader-switchViewIcon,
.MuiPickersCalendarHeader-switchViewButton svg,
.MuiPickersArrowSwitcher-button svg,
.MuiInputAdornment-root .MuiSvgIcon-root {
  color: var(--text-secondary) !important;
}

.MuiDayPicker-weekDayLabel,
.MuiPickersDay-dayLabel {
  color: var(--text-secondary) !important;
}

/* Calendar header month/year text */
.MuiPickersCalendarHeader-label {
  color: var(--text-secondary) !important;
}

/* Icon button customization */
.MuiIconButton-root .MuiSvgIcon-root {
    color: var(--text-secondary) !important;
  }
  
  .MuiIconButton-root:hover .MuiSvgIcon-root {
    color: var(--text-primary) !important;
  }
